




































































































































































































































import {
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
} from '@vue/composition-api'
import 'vue-good-table/dist/vue-good-table.css'
import CheckboxSelector from '@/components/commune/components/filter/CheckboxSelector.vue'
import CustomPagination from '@/components/commune/components/filter/CustomPagination.vue'
import CustomHeader from '@/components/commune/components/filter/CustomHeader.vue'
import { useMutations, useState } from '@u3u/vue-hooks'
import DeleteUser from '@/components/commune/components/DeleteUser.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import * as check from '@/composables/commune'

export default defineComponent({
  name: 'vue-table',
  components: {
    CheckboxSelector,
    CustomLink,
    DeleteUser,
    CustomPagination,
    CustomHeader,
  },
  props: {
    // Headers array of string
    headers: {
      type: Array as () => Array<string>,
      required: true,
    },
    // date for table and list. /!\ entries must be like headers
    data: {
      type: Array as () => Array<any[]>,
      required: true,
    },
    filter: {
      type: Array as () => Array<string>,
      required: false,
      default() {
        return []
      },
    },
    // Last column to have a button for example
    lastColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const search = ref('')
    let departement: string[] = []
    let roles: string[] = []
    const inputValue = reactive({ Departement: departement })
    const itemsToDisplay = ref(props.data)
    const { SET_EDIT_USER } = useMutations('commune', ['SET_EDIT_USER'])
    const showModal = ref(false)

    onBeforeMount(() => SET_EDIT_USER(''))

    const onClick = data => {
      SET_EDIT_USER(data.uid)
      ctx.root.$router
        .push({ name: 'CommuneEditUser' })
        .catch(ctx.root.$logger.trace)
    }

    const onClickDelete = data => {
      SET_EDIT_USER(data.uid)
      showModal.value = true
    }
    // Recupere le emit pour filtrer via les departements
    ctx.root.$on('checkbox:selector:departement', data => {
      departement = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les roles
    ctx.root.$on('checkbox:selector:roles', data => {
      roles = data
      filterGlobal()
    })
    // Filtrage du tableau
    const filterGlobal = () => {
      itemsToDisplay.value = props.data
      if (departement.length < 1 && roles.length < 1) {
        itemsToDisplay.value = props.data
      }

      if (departement.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          departement.includes(x.departement)
        )
      }
      if (roles.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          findOne(x.roles, roles)
        )
      }
    }

    // Fonction pour retrouver un element dans un tableau
    const findOne = (haystack, arr) => arr.some(v => haystack.indexOf(v) >= 0)

    // Recupere l'event pour fermer le user
    ctx.root.$on('modal:confirm:close', () => {
      showModal.value = false
      SET_EDIT_USER('')
    })
    // TODO quand on delete un user , recupere l'event pour fermer le modal et faire encore une update du tableau
    ctx.root.$on('modal:confirm:delete', () => {
      showModal.value = false
      SET_EDIT_USER('')
    })
    // Fermer le modal via la croix
    const closeModal = () => {
      showModal.value = false
      SET_EDIT_USER('')
    }

    const change = () => {
      ctx.root.$emit('reset-pagination')
    }

    return {
      search,
      onClick,
      departement,
      itemsToDisplay,
      inputValue,
      showModal,
      closeModal,
      ...useState('commune', ['i18n']),
      DeleteUser,
      onClickDelete,
      check,
      change,
    }
  },
})
