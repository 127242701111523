















































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
import Axios from 'axios'
import CustomLink from '@/components/g/CustomLink.vue'
import Message from '@/components/ui/Message.vue'

export default defineComponent({
  name: 'delete-user',
  components: {
    CustomLink,
    Message,
  },
  props: {
    uid: { type: Object, required: true },
    parentInputStyle: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { userEdit } = useState('commune', ['userEdit'])
    const { i18n } = useState('commune', ['i18n'])
    const { isAdmin } = useGetters('user', ['isAdmin'])
    // const { FETCH_USERS } = useActions('commune', ['FETCH_USERS'])
    const isLoading = ref(false)
    const no = () => {
      ctx.root.$emit('modal:confirm:close')
      isLoading.value = false
    }

    const deleteUser = async () => {
      isLoading.value = true
      if (userEdit.value.length > 0 && isAdmin.value) {
        await Axios.delete(`${getApiUrl()}/town/users/${userEdit.value}`, {
          data: { source: '' },
        }).then(resp => {
          ctx.root.$emit('table:refresh:data')
        })
      }
    }

    return {
      deleteUser,
      no,
      i18n,
      isLoading,
    }
  },
})
