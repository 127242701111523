var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"commune-users pb-xxl"},[_c('header',[_c('breadcrumb',{attrs:{"content":_vm.breadcrumbUser}}),_c('div',{staticClass:"page-header wrapper"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"main-title",domProps:{"innerHTML":_vm._s(_vm.i18n.communeUsers.title)}}),(!_vm.isLoadingUsers)?_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.dataList.length)+" "+_vm._s(_vm.i18n.userGestion.utilisateurs))]):_vm._e()]),_c('div',{staticClass:"page-header__actions"},[_c('CustomLink',{staticClass:"add-user",attrs:{"content":{
            label: _vm.i18n.communeUsers.labelButton,
            to: { name: 'CommuneAddUser' },
          },"modifiers":[
            'btn',
            'green',
            'white',
            'big',
            'rectrounded',
            'uppercase' ]}})],1)])],1),_c('div',{staticClass:"wrapper"},[(_vm.i18n.communeUsers.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.communeUsers.optionHtml.htmltext)}}):_vm._e(),(!_vm.isLoadingUsers)?[_c('message-delete',{attrs:{"color":'success',"label":_vm.i18n.error.delete,"type":'delete',"value":_vm.deleteMessage}}),_c('Table',{attrs:{"data":_vm.dataList,"headers":_vm.columns,"lastColumn":true}})]:_c('g-loader',{staticClass:"users-loader mt-s",attrs:{"modifiers":['big']}})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }