









































































































































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import Message from '@/components/ui/Message.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import Table from '@/components/commune/components/UsersTable.vue'
import MessageDelete from '@/components/commune/components/filter/Message.vue'

import * as check from '@/composables/commune'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'commune-users',
  components: {
    Message,
    Breadcrumb,
    Header,
    Table,
    CustomLink,
    MessageDelete,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { i18n } = useState('commune', ['i18n'])
    const { getTableRoles } = useGetters('commune', ['getTableRoles'])
    const { FETCH_USERS, FETCH_ROLES } = useActions('commune', [
      'FETCH_USERS',
      'FETCH_ROLES',
    ])
    const message = ref(true)
    const breadcrumbUser = [
      {
        label: i18n.value.communeUsers.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const keys = ['fullname', 'departement', 'roles', 'actif']
    const search = ref('')
    const departement = ref([])
    const isLoadingUsers = ref(false)
    const dataList = ref()
    const deleteMessage = ref(false)

    // declaration des colonnes du tableau
    const columns = [
      {
        label: i18n.value.userGestion.fullname,
        field: 'fullname',
        thClass: 'fullname',
        tdClass: 'fullname',
      },
      {
        label: i18n.value.userGestion.departement,
        field: 'departement',
        type: 'string',
        thClass: 'departement',
        tdClass: 'departement',
      },
      {
        label: i18n.value.userGestion.roles,
        field: 'rolesShow',
        type: 'string',
        thClass: 'roles',
        tdClass: 'roles',
      },
      {
        label: i18n.value.userGestion.statut,
        field: 'actif',
        type: 'string',
        thClass: 'statut',
        tdClass: 'statut',
      },
      {
        label: '',
        field: 'btn',
        html: true,
        globalSearchDisabled: true,
        sortable: false,
        thClass: 'actions',
        tdClass: 'actions',
      },
    ]
    // recuperer les roles si il n y a pas de users (f5)
    onBeforeMount(async () => {
      isLoadingUsers.value = true
      if ((await getTableRoles.value.length) < 1) {
        await FETCH_ROLES()
      }
      await FETCH_USERS()
      dataList.value = check.getUsersList()
      isLoadingUsers.value = false
    })
    // recuperer la liste des users apres un delete
    ctx.root.$on('table:refresh:data', async () => {
      isLoadingUsers.value = true
      await FETCH_USERS().then(() => {
        dataList.value = check.getUsersList()
        deleteMessage.value = true
        isLoadingUsers.value = false
      })
    })
    // fermer les popup delete quand on supprime
    ctx.root.$on('modal:confirm:delete', () => {
      deleteMessage.value = true
    })
    // fermer les popup delete quand on close
    ctx.root.$on('popup:confirm:close', () => {
      deleteMessage.value = false
    })

    return {
      message,
      isLoadingUsers,
      content,
      i18n,
      dataList,
      keys,
      breadcrumbUser,
      columns,
      search,
      departement,
      deleteMessage,
    }
  },
})
